<template>
  <div class="title">
    <h2>{{ title }}</h2>
    <p>{{ dsc }}</p>
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    props: {
      title: {
        type: String,
        default: '',
      },
      dsc: {
        type: String,
        default: '',
      },
    },
    components: {},
    mounted() {},

    methods: {},
  }
</script>
<style lang="less" scoped>
  .title {
    // margin: 80px 0 0 0;
    width: 1100px;
    margin: auto;
    margin-top: 80px;
    padding: 50px 0;
    text-align: left;
    h2 {
      color: #425672;
      font-size: 40px;
      margin-bottom: 10px;
    }
    p {
      color: #999999;
      font-size: 18px;
    }
  }
</style>