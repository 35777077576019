import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/reset.css"; // 重置样式


// import "amfe-flexible";
// 跳转后返回顶部
// eslint-disable-next-line no-unused-vars


router.afterEach((_to, _from, _next) => {
  window.scrollTo(0, 0);
});
const app = createApp(App);
// import * as echarts from "echarts";
// app.config.globalProperties.$echarts = echarts;

app.use(store);
app.use(router);
app.use(ElementPlus);
app.mount("#app");
// createApp(App).use(store).use(router).mount("#app");
