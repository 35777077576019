<template>
  <div id="header">
    <!-- ,'block':$route.path!='/'  -->
    <div class="header">
      <div class="left" @click="Tiao">
        <div class="img">
          <img src="../assets/image/logo.png" alt="" />
        </div>
      </div>
      <div class="right">
        <ul>
          <router-link to="/" tag="li">首页</router-link>
          <router-link to="/center" tag="li">关于我们</router-link>
          <router-link to="/spot" tag="li">产品中心</router-link>
          <router-link to="/system" tag="li">成功案例</router-link>
          <!-- <router-link to="/news" tag="li">企业动态</router-link> -->
          <router-link to="/about" tag="li">合作伙伴</router-link>
          <router-link to="/worth" tag="li">联系我们</router-link>
        </ul>
      </div>
      <div class="tiao">
        <router-link to="/screenindex" tag="li" v-if="status==1">智慧公厕可视化看板</router-link>

        
        <!-- <a href="https://www.dundd.cn:8182/#/login">云汇普适万物互联指挥中心</a> -->
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        off: false,
        status:""
      }
    },

    mounted() {
      this.status=localStorage.getItem('status');
      window.addEventListener('scroll', this.handleScroll);

    },
    // watch:{
    //   '$route': { // $route可以用引号，也可以不用引号  监听的对象
    //           handler(to){
    //           console.log('当前页面路由：' + to.path);
    //           // console.log('上一个路由：' + from);
    //           },
    //           deep: true, // 深度观察监听 设置为 true
    //           immediate: true, // 第一次初始化渲染就可以监听到
    //       }
    // },

    methods: {
      //锚点跳转
      goAnchor() {
        this.$router.push({
          path: '/',
          query: {
            bottom: 1,
          },
        })
      },

      handleScroll() {
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop
        // console.log("滚动距离" + scrollTop);
        if (scrollTop > 650) {
          this.off = true
        } else {
          this.off = false
        }
      },
      Tiao() {
        this.$router.push('/')
      },
    },
  }
</script>
<style lang="less" scoped>
  #header {
    background: #020234;
    height: 100px;
    //position: fixed;
    //top: 0;
    //left: 0;
    //width: 100%;
    //z-index: 99;
    .header {
      //margin: 0 auto;
      padding: 0 60px;
      height: 100px;
      display: flex;
      justify-content: space-between;
      //width: calc(100% - 140px);
      //width: 1100px;
      background: rgba(0, 0, 0, 0.5);
      transition: 0.3s all ease-in-out;

      .left {
        //padding-left: 130px;
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 26px;
        cursor: pointer;
        &:hover {
          color: #1f82ed;
        }
        .img {
          margin-right: 18px;
          img {
            width: 85px;
            height: 49px;
          }
        }
      }
      .right {
        display: flex;
        align-items: center;
        font-size: 18px;

        ul {
          display: flex;
          a,
          li {
            padding: 0 40px;
            line-height: 80px;
            height: 90px;
            color: #c0c0c0;
            cursor: pointer;
            &:hover {
              color: #1f82ed;
            }

            &:last-child {
              border-right: none;
            }
          }
          a.router-link-exact-active {
            color: #1f82ed;
            border-bottom: 4px solid #ffffff;
          }
        }
      }
    }
    .tiao {
      //padding-right: 100px;
      display: flex;
      align-items: center;
      a {
        color: #00b1ff;
        //padding: 0 50px;
        cursor: pointer;
        font-size: 16px;
      }
    }
    .white {
      background: #fff;
      box-shadow: 0px 15px 10px -15px #999;
      transition: 0.3s all ease-in-out;

      .left {
        color: #282525e0;
      }
      .right {
        ul {
          a,
          li {
            color: #282525e0;
            &:last-child {
              border-right: none;
            }
          }
        }
        .tiao {
          a {
            color: #00b1ff;
          }
        }
      }
    }
    .block {
      background: rgba(0, 0, 0, 1);
    }
  }
  @media only screen and (max-width: 1800px) {
    #header {
      .header {
        .left {
          font-size: 22px;
        }
        .right {
          font-size: 16px;
          ul {
            a,
            li {
              padding: 0 25px;
            }
          }
        }
      }
    }
  }
</style>