<template>
  <swiper
    :slides-per-view="1"
    :loop="true"
    :autoplay="true"
    :pagination="true"
    :modules="modules"
  >
    <swiper-slide>
      <img src="../assets/image/bannner.png" alt="" />
    </swiper-slide>
    <swiper-slide>
      <img src="../assets/image/banner2.png" alt="" />
    </swiper-slide>
    <swiper-slide>
      <img src="../assets/image/banner3.png" alt="" />
    </swiper-slide>
  </swiper>
</template>
<script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue'
  import { Pagination, Autoplay } from 'swiper'

  // Import Swiper styles
  import 'swiper/css'
  import 'swiper/css/pagination'
  import 'swiper/css/autoplay'
  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      return {
        modules: [Autoplay, Pagination],
      }
    },
  }
</script>

<style lang="less" scoped>
  img {
    width: 100%;
    height: 100%;
  }
</style>