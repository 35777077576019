<template>
  <div id="app2">
    <Header v-show="off" />
    <router-view />
    <Footer v-show="off" />
    <tips v-show="off" />
  </div>
</template>
<script setup>
import { codeLogin } from "@/assets/api";

  // @ is an alias to /src
  import Header from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'
  import { useRoute } from 'vue-router'
  import { computed } from 'vue'
  import tips from './components/tips.vue'
  import { ref } from "vue";
  const route = useRoute()

  const off = computed(() => {
    // 这里列出不想显示Header的路由路径
    const noHeaderPaths = ['/assistant','/screenindex']
    // 如果当前路由的路径不在列表中，返回true来显示Header
    return !noHeaderPaths.includes(route.path)
  })
  let code=ref({
    // code:"56834938-3ecc-4c59-a388-d2eaf1b21995"
    code:"ee40f9a3-f8bd-4adc-b378-4ca75f9fcaa5"
    
  });
    codeLogin(code.value).then((response) => {
      if (response.code == 200) {
        // this.$store.commit("SET_TOKEN", response.token);
        // this.$store.commit("SET_USERINFO", "");
        // this.$message({
        //   type: "success",
        //   message: `登录成功`,
        // });
        // setTimeout(() => {
        //   this.$router.push({
        //     path: "/",
        //   });
        // }, 500);
        localStorage.setItem("status", response.status);
        localStorage.setItem("Access-Token", response.token);
        localStorage.setItem("SET_USERINFO", '');
        
      } else {
        // this.$message.error(response.msg);
      }
    });
</script>
<style lang="less">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #333;
  }
  #app2 {
    //width:1920px;
    min-width: 1200px;
    // font-family: Avenir, Helvetica, Arial, sans-serif;
    // -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
    // min-height: 100vh;
  }
</style>