<template>
  <div id="footer">
    <div class="con footers">
      <div class="left">
        <div class="phone"><span>手机：</span>199-8054-5078</div>

        <div class="phone"><span>电话：</span>400-7575-666</div>
        <div class="xia">
          <div class="guanwang">
            <span>官网：</span><span>https://www.dundd.cn</span>
          </div>
          <p class="dizhi"> 地 址：成都市武侯区武科西五路38号 四川盈创空间创意中心3栋601 </p>
        </div>
      </div>
      <div class="right">
        <div>
          <img alt="" src="../assets/image/gongzhong.png" />
          <p>中云汇公众号</p>
        </div>
        <!-- <div>
          <img alt="" src="../assets/image/xiaochengxu.png" />
          <p>蹲蹲小程序</p>
        </div> -->
        <div>
          <img alt="" src="../assets/image/douyin.png" />
          <p>中云汇抖音号</p>
        </div>
        <!-- <div>
          <img alt="" src="../assets/image/wb.png" />
          <p>新浪微博</p>
        </div> -->
      </div>
    </div>
    <p class="icp">
      © 2021 中云汇（成都）物联科技有限公司 <a href="https://beian.miit.gov.cn" class="ICP">蜀ICP备2021009379号-1</a> </p>
    <!-- <p class="icps"> 提示：投资有风险，选择需谨慎 </p> -->
  </div>
</template>

<script>
  export default {
    name: 'Footer',
  }
</script>

<style lang="less" scoped>
  #footer {
    background: #020234;
    height: 360px;

    .con {
      width: 1100px;
      display: flex;
      justify-content: space-between;
      text-align: left;
      align-items: center;
      height: 290px;
    }

    .left {
      // width: 395px;

      .phone {
        font-size: 28px;
        font-weight: 700;
        color: #fff;

        span {
          font-size: 20px;
          letter-spacing: 3px;
        }
      }

      p {
        margin-top: 10px;
        font-size: 20px;
        color: #fff;
        //margin-bottom: 10px;
      }
    }

    .right {
      width: 290px;
      display: flex;
      justify-content: space-between;
      text-align: center;

      div {
        p {
          font-size: 16px;
          color: #fff;
          margin-top: 10px;
        }
      }

      img {
        width: 131px;
        height: 130px;
      }
    }
  }

  .icp {
    color: #ffffff;
    font-size: 20px;
    font-family: PingFangSC-Regular-, PingFangSC-Regular;
    .ICP{
      color: #ffffff;
      font-size: 20px;
    }
  }
  .icps {
    color: #ddd;
    font-size: 10px;
  }
  .guanwang {
    span {
      font-size: 20px;
      color: #fff;
      letter-spacing: 3px;
    }
  }
</style>
