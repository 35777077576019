import request from '../js/request'

// const requestUrl = 'http://192.168.31.205:18089'
const requestUrl = 'https://dundd.cn:11022'
// const requestUrl = ' http://172.16.35.254:8080'
// const requestUrl = ' http://172.16.35.45:11023'

// 登录
export function loginApi(name, password) {
  return request({
    method: 'post',
    data: {username:`${name}`,password:`${password}`},
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    url: requestUrl + '/login'
  })
}

//获取厕所
export function getToilet(data) {
  return request({
    method: 'get',
    data:{},
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    url: requestUrl + `/toilet/toilet/list?${data}`
  })
}
//获取厕所
export function getToiletInfo(data) {
  return request({
    method: 'get',
    data:{},
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    url: requestUrl + `/toilet/deviceInfo/${data}`
  })
}
//控制设备
export function controlDemo(data) {
  return request({
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    url: requestUrl + `/toilet/event/control`
  })
}
//获取设备信息
export function getEquipment(data) {
  return request({
    method: 'get',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    url: requestUrl + `/toilet/device/all/${data}`
  })
}
//获取维保事件
export function getMaintenance(data) {
  return request({
    method: 'get',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    url: requestUrl + `/toilet/event`
  })
}
//获取echarts
export function getEchartsInfo(data) {
  return request({
    method: 'get',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    url: requestUrl + `/toilet/deviceInfo/manure/${data}`
  })
}
//获取维保
export function getEventList(data) {
  return request({
    method: 'get',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    url: requestUrl + `/toilet/event/list`
  })
}
//已读消息
export function changeTag(data) {
  return request({
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    url: requestUrl + `/toilet/event/eventTag/`
  })
}
//获取萤石云token
export function getYSY(data) {
  return request({
    method: 'get',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': localStorage.getItem('Access-Token')
    },
    url: requestUrl + `/toilet/device/token`
  })
}
//获取top5
export function getCustom(data) {
  return request({
    method: 'get',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': localStorage.getItem('Access-Token')
    },
    url: requestUrl + `/toilet/people/top_consumer`
  })
}
//获取客流量
export function getEvaluate(data) {
  return request({
    method: 'post',
    data: { toiletId:data || 0},
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': localStorage.getItem('Access-Token')
    },
    url: requestUrl + `/toilet/evaluate/total`
  })
}
//总体统计数据
export function getStatistics(data) {
  return request({
    method: 'get',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': localStorage.getItem('Access-Token')
    },
    url: requestUrl + `/toilet/toilet/statistics`
  })
}
//电量
export function getHalfMonth(data) {
  return request({
    method: 'post',
    data: { toiletId:data || 0},
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': localStorage.getItem('Access-Token')
    },
    url: requestUrl + `/toilet/electric/half_month`
  })
}
//肥料
export function getManureList(data) {
  return request({
    method: 'get',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': localStorage.getItem('Access-Token')
    },
    url: requestUrl + `/toilet/manure/total`
  })
}
//获取维保
export function getMainteventNum(data) {
  return request({
    method: 'post',
    data: { toiletId:data || 0},
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    url: requestUrl + `/toilet/event/undo_maintevent_num`
  })
}
//获取维保
export function getMainteventInfo(data) {
  return request({
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    url: requestUrl + `/toilet/event/undo_maintevent_info`
  })
}
//获取人流
export function getDayConsumer(data) {
  return request({
    method: 'post',
    data: { toiletId:data || 0},
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    url: requestUrl + `/toilet/people/day_consumer`
  })
}
//搜索厕所
export function searchToilet(data) {
  return request({
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    url: requestUrl + '/toilet/toilet/locationtoilet'
  })
}
//退出登录
export function logoutApi(data) {
  return request({
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    url: requestUrl + '/logout'
  })
}
//获取定制必要信息
export function getCustomizedTable(data) {
  return request({
    method: 'get',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    url: requestUrl + '/system/customized/getCustomizedTable'
  })
}
//通知站长
export function remoteIntercom(data) {
  return request({
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    url: requestUrl + '/toilet/toilet/RemoteIntercom'
  })
}
// 商业运营
export function choiceamount(data) {
  return request({
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    url: requestUrl + '/toilet/productamount/choiceamount'
  })
}

// 虚拟接口
export function getToiletCountConfound(){
  return request({
    method:'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    url: requestUrl + '/toilet/toilet/getToiletCountConfound'
  })
}
// 编码登录
export function codeLogin(data){
  return request({
    method:'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    data:data,
    url: requestUrl + '/system/codeLogin/codeLogin'
  })
}
// 左上角好评月排行榜
export function getEvaluateRankList(data){
  return request({
    method:'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    data:data,
    url: requestUrl + '/toilet/evaluate/getEvaluateRankList'
  })
}
// 评价趋势
export function statisticsByDay(data){
  return request({
    method:'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    data:data,
    url: requestUrl + '/toilet/evaluate/statisticsByDay'
  })
}
// 评价反馈
export function getEvaluateTypePercent(data){
  return request({
    method:'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    data:data,
    url: requestUrl + '/toilet/evaluate/getEvaluateTypePercent'
  })
}
// 地图 areaCode
export function getAreaToiletInfo(data){
  return request({
    method:'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    data:data,
    url: requestUrl + '/toilet/toilet/getAreaToiletInfo'
  })
}
// 监控告知
export function useMonitorRecord(data){
  return request({
    method:'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    data:data,
    url: requestUrl + '/toilet/toilet/useMonitorRecord'
  })
}
// -----------------离线监测接口---
// 查询设备
export function getDevice(){
  return request({
    method:'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    url: requestUrl + '/toilet/haveDevice/getDevice'
  })
}
// 设备异常信息
export function searchWarning(data){
  return request({
    method:'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    data:data,
    url: requestUrl + '/toilet/checkAbnormalWarning/searchWarning'
  })
}
// 设备在线状态
export function machineIsOnline(data){
  return request({
    method:'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    data:data,
    url: requestUrl + '/toilet/checkAbnormalWarning/machineIsOnline'
  })
}
// 各驿站设备
export function haveDevicesearch(data){
  return request({
    method:'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    data:data,
    url: requestUrl + '/toilet/haveDevice/search'
  })
}
// 查询异常离线详细信息
export function abnormalWaringInfo(data){
  return request({
    method:'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    data:data,
    url: requestUrl + '/toilet/checkAbnormalWarning/abnormalWaringInfo'
  })
}
// 单设备历史状态
export function deviceWithTime(data){
  return request({
    method:'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization':localStorage.getItem('Access-Token')
    },
    data:data,
    url: requestUrl + '/toilet/checkAbnormalWarning/deviceWithTime'
  })
}