<template>
  <div class="particles-js-box">
    <div id="particles-js"></div>
    <div class="title">
      <div class="hed-5g">5G智慧轻松驿站</div>
      <div class="dsc-5g">OUR PRODUCTS</div>
    </div>
    <div class="videos">
      <video
        id="media"
        controls
        poster="../../assets/image/public.png"
        src="https://facecapture.oss-cn-shenzhen.aliyuncs.com/VIDEO/FeiFanJiangRen.mp4"
      ></video>
    </div>
    <div class="classifys">
      <div class="classify">
        <div class="frame-right">
          <img src="../../assets/image/img-1.png" />
          <h2>SAAS云平台</h2>
          <p>
            拥有<span style="color: #00baff; font-size: 14px; font-weight: 600"
              >SAAS</span
            >云平台
          </p>
          <span>提供大数据运营服务</span>
        </div>
        <div class="frame-right">
          <img src="../../assets/image/img-2.png" />
          <h2>相关专利</h2>
          <p>
            公司外观设计<span
              style="color: #00baff; font-size: 14px; font-weight: 600"
              >7</span
            >项
          </p>
          <span
            >商标专利<span
              style="color: #00baff; font-size: 14px; font-weight: 600"
              >15</span
            >项</span
          >
        </div>
        <div class="frame-right">
          <img src="../../assets/image/img-3.png" />
          <h2>核心产品</h2>
          <p>提供国内</p>
          <span style="color: #00baff; font-size: 14px; font-weight: 600"
            >"无下水道智慧碳中和厕所"</span
          >
        </div>
        <div>
          <img src="../../assets/image/img-4.png" />
          <h2>生物科技</h2>
          <p>应用微生物降解技术</p>
          <span style="color: #00baff; font-size: 14px; font-weight: 600"
            >实现生态保护，粪土资源化</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  import particlesJs from './particles'
  import particlesConfig from './particles.json'

  export default {
    name: 'index',
    data() {
      return {
        video: null,
        play: false,
      }
    },
    mounted() {
      this.init()
      // 6000
      window.addEventListener('scroll', this.handleScroll)
      this.video = document.getElementById('media')
      let that = this
      this.video.addEventListener('play', () => {
        //播放开始执行的函数
        that.play = true
        //执行开始播放的路平级
      })
      this.video.addEventListener('pause', () => {
        //播放开始执行的函数
        that.play = false
        //执行开始播放的路平级
      })
      // console.log(document.getElementById("public").getBoundingClientRect().top)
    },
    methods: {
      init() {
        particlesJs('particles-js', particlesConfig)
      },
      handleScroll() {
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop
        // console.log("滚动距离" + scrollTop);
        if (scrollTop > 2300) {
          this.play = false
          this.video.pause()
        }
      },
      Play() {
        this.video.play()
        this.play = true
      },
    },
  }
</script>

<style lang="less" scoped>
  .particles-js-box {
    width: 100%;
    height: 100%;
  }

  #particles-js {
    width: 100%;
    background: #00133e;
    height: 1000px;
    position: absolute;
  }

  //标题
  .title {
    margin: 0 auto;
    padding-top: 40px;
    width: 300px;
    height: 100px;
    position: relative;
    z-index: 1;

    .hed-5g {
      font-size: 40px;
      color: #ffffff;
    }

    .dsc-5g {
      padding-top: 20px;
      font-size: 18px;
      color: #ffffff;
    }
  }

  //视频
  .videos {
    padding-top: 40px;
    position: relative;
    z-index: 1;
    margin: 0 auto;
    width: 720px;
    height: 480px;
    display: flex;
    justify-content: space-between;

    video {
      width: 720px;
      height: 480px;
    }

    .titles {
      text-align: left;
      width: 420px;
      height: 333px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .describes {
        height: 94px;

        p {
          font-size: 16px;
          color: #ffffff;
        }
      }

      .specifics {
        p {
          font-size: 16px;
          color: #ffffff;
        }
      }
    }

    //position: absolute;
    //top: 217px;
    //left: 420px;
    //.bo{
    //  cursor: pointer;
    //  position: absolute;
    //  top: 40%;
    //  left: 48%;
    //  height: 30px;
    //  width: 30px;
    //}
  }

  //描述
  .describe {
    text-align: left;
    width: 550px;
    height: 94px;
    position: absolute;
    top: 216px;
    right: 300px;

    > p {
      font-size: 16px;
      color: #ffffff;
    }
  }

  //具体内容
  .specific {
    width: 550px;
    height: 194px;
    text-align: left;
    position: absolute;
    bottom: 284px;
    right: 300px;

    > p {
      font-size: 16px;
      color: #e6e6e6;
    }
  }

  //分类
  .classifys {
    padding-top: 40px;
    position: relative;

    .classify {
      margin: 70px auto;
      width: 1000px;
      height: 200px;
      display: flex;
      justify-content: space-between;

      > div {
        width: 250px;
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;

        > img {
          width: 58px;
          height: 58px;
        }

        > h2 {
          padding: 20px 0;
          font-size: 16px;
          font-weight: bold;
          color: #00baff;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          letter-spacing: 2px;
        }

        > p {
          font-size: 14px;
          color: #e6e6e6;
        }

        > span {
          font-size: 14px;
          color: #e6e6e6;
        }
      }
    }
  }

  //边框
  .frame-right {
    border-right: 2px solid hsl(0deg 0% 100% / 31%);
  }
</style>