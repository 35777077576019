import axios from 'axios'
// import { Message, Notification } from 'element-plus'
// import store from '@/store'
// import router from '@/router'
// import { codeLogin } from "@/api";

let messageInstance = null;

const resetMessage = (options)=>{
//关闭上一个弹窗，继续下一个弹窗
  if(messageInstance){
    messageInstance.close()
  }
  messageInstance = Message(options)
}
const service = axios.create({
  // baseURL: process.env.VUE_APP_API_DOMAIN,
  timeout: 60000 // request timeout
})

service.interceptors.request.use(request => {
  // const language = store.state.language === 'zh' ? 1 : 2
  // request.headers[`Access-Token`]=localStorage.getItem('Access-Token')
  // request.headers[`Content-Style`]="json"
  // request.headers[`Content-Token`]="Admin"
  // request.headers[`Content-Key`]="Admin"
  // request.headers[`Content-Type`]="application/x-www-form-urlencoded"
  return { ...request}
})

service.interceptors.response.use(
  response => {
    var res =response.data;
    if(res.msg.indexOf('认证失败，无法访问系统资源')!=-1){
      // const message = '长时间未操作，请重新登录'
      // resetMessage({
      //   message,
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      // store.commit("REMOVE_USERINFO");
      // store.commit("REMOVE_TOKEN");
      // store.commit("REMOVE_AUTH");
      // let code = window.location.href.split("?code=")[1];
      // let dataa = {
      //   code: code,
      // };
      // console.log(code)
      // codeLogin(dataa).then((ress) => {
      //   if (ress.code == 200) {
      //     store.commit("SET_TOKEN", ress.token);
      //     store.commit("SET_USERINFO", "");
      //     // this.$message({
      //     //   type: "success",
      //     //   message: `登录成功`,
      //     // });
      //     // setTimeout(() => {
      //     //   this.$router.push({
      //     //     path: "/",
      //     //   });
      //     // }, 500);
      // console.log(11111);
      // return;

      //   } else {
      //     this.$message.error(ress.msg);
      //   }
      // });
      // router.replace({path: '/login'})
    }else{
      //正常
      return Promise.resolve(res)
    }
  },
  error => {
    const { response = false } = error
    // return Promise.reject(error)
    return Promise.resolve(error)
  }
)
export default service
