<template>
  <div class="tips">
    <RouterLink to="/assistant" class="tips1-img">
      <img src="../assets/image/onlineServe2.png" alt="" srcset="" />
    </RouterLink>
  </div>
</template>

<script setup></script>

<style lang="less" scoped>
  .tips {
    width: 150px;
    height: 180px;
    position: fixed;
    bottom: 20px;
    right: 10px;
    z-index: 999;

    .tips1-img {
      width: 150px;
      height: 180px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
</style>