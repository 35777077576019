import { createRouter, createWebHashHistory } from "vue-router";
import index from "../views/index.vue";

const routes = [
  {
    path: "/",
    component: index,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/about.vue"),
  },
  {
    path: "/center",
    name: "center",
    component: () =>
      import("../views/center.vue"),//产品中心
  },
  {
    path: "/spot",
    name: "spot",
    component: () =>
      import("../views/spot.vue"),//产品亮点
  },
  {
    path: "/system",
    name: "system",
    component: () =>
      import("../views/system.vue"),//
  },
  {
    path: "/worth",
    name: "worth",
    component: () =>
      import("../views/worth.vue"),//
  },
  {
    path: "/news",
    name: "news",
    component: () =>
      import("../views/news.vue"),
  },
  {
    path: "/assistant",
    name: "assistant",
    component: () =>
      import("@/views/smartAssistant.vue"),
  },
  {
    path: "/screen",
    name: "screen",
    component: () =>
      import("@/views/screen.vue"),
  },
  {
    path: "/screenindex",
    name: "screenindex",
    component: () =>
      import("@/views/screenindex.vue"),
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  // 对于页面跳转，全部都返回到页面顶部。
  /* eslint-disable */
  //  scrollBehavior(to,from,savedPosition){
  //  //切换路由自动滚动到顶部
  //  if (to.hash){
  //  return{
  //  selector:to.hash//锚点运用时地址/#gengxing
  //  };
  //  }else{
  //  return{x:0,y:0};
  //  }
  //  },
});

export default router;
